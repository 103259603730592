.seating-layout-container {
    touch-action: none;
    user-select: none;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.seat-tooltip {
    position: absolute;
    background: white;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    pointer-events: none;
    z-index: 1000;
}